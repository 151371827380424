/* src/pages/search.js */
import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import axios from 'axios'
import { getColor,getTransmissionType,getFuelType,getMileage,getCost } from '../Modules/filter-data-tools'
import Layout from "../components/layout"
import '../scss/srp-side-style.scss'
import '../scss/srp-fonts.scss'
import '../scss/srp-normalize.scss'

import { makeStyles } from '@material-ui/core/styles'
  ;
import Slider from '@material-ui/core/Slider';
import SearchRecommended from "./SearchRecommended"
import { FinanceCalculatorProvider } from "../components/modal/financing/storage/CalculatorContext"
import InnerHTML from 'dangerously-set-html-content'

import { captureEvent } from "event-service/modules/capture"

// We can access the results of the page GraphQL query via the data props
const SearchPage = ({ data, location, pageContext }) => {
  const useStyles = makeStyles({
    root: {
      width: 300,
    },
  });
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  const DealerMasterEvent = (typeof window !== 'undefined') ? new CustomEvent('dealer-masters-srp-load') : {};
  const localStorage = typeof window !== 'undefined' && window.localStorage
  const document = typeof window !== 'undefined' && window.document
  const history = typeof window !== 'undefined' && window.history
  const siteSettings = JSON.parse(data.siteData.siteData.siteSettings)
  const pageContentSEO = pageContext?.pageContent?.SEO?.seo;
  const pageContentSRP = pageContext?.pageContent?.SRP?.querySearchParams;

/*   useEffect(()=>{
    if(pageContentSRP !== (null || undefined)){
      const url = window.location.href
      history.replaceState({ path: url }, null, `?${pageContentSRP}`);

    }

  },[SearchParam]) */
  let pageSeo = {seo: pageContentSEO? pageContentSEO : siteSettings.seo.search}
  let theSlug = siteSettings.inventorymanagement.vdpslug
  let useCompactCards = siteSettings.inventorymanagement.useCompactCards
  let SRPCardBtns = siteSettings.inventorymanagement.vehiclecardbutton
  let CostRange = parseInt(siteSettings.inventorymanagement.costrange)
  let MileageRange = parseInt(siteSettings.inventorymanagement.mileagerange)
  let srpPageCount = parseInt(siteSettings.inventorymanagement.srpCardCount);


  const lead_url = data.site.siteMetadata.lead_url
  const site_id = data.site.siteMetadata.site_id
  const open_search_url = data.site.siteMetadata.opensearchAPI

  const gtmId = siteSettings.dealerInfo.gtmId

  const topHtmlSRP = siteSettings.inventorymanagement.topHtmlSRP
  const botHtmlSRP = siteSettings.inventorymanagement.botHtmlSRP

  const staticTopHtmlSrp =  pageContext?.pageContent?.SRP?.topHtmlStaticSRP;
  const staticBottomHtmlSRP = pageContext?.pageContent?.SRP?.botHtmlStaticSRP;

  const overwriteCTAs = pageContext?.pageContent?.SRP?.overwriteCTAs;
  const staticBtns = pageContext?.pageContent?.SRP?.SRPSettings;
  const overwriteInlineOffers = pageContext?.pageContent?.InlineOffers?.overwriteInlineOffers;
  const staticSrpInlineOffers = pageContext?.pageContent?.InlineOffers;

  const unavailableImg = siteSettings.inventorymanagement.unavailableImg
  const disclaimer = siteSettings.inventorymanagement.disclaimerSRP
  const disclaimerCalculator = siteSettings.inventorymanagement.disclaimerCalculator
  const disclaimerContact = siteSettings.inventorymanagement.disclaimerContact
  const disclaimerFinanceApp = siteSettings.inventorymanagement.disclaimerFinanceApp
  const downpayment = Number(siteSettings.inventorymanagement.downpayment)
  const rates =  [
    Number(siteSettings.inventorymanagement.aRate),
    Number(siteSettings.inventorymanagement.bRate),
    Number(siteSettings.inventorymanagement.cRate),
    Number(siteSettings.inventorymanagement.dRate),
    Number(siteSettings.inventorymanagement.eRate),
    Number(siteSettings.inventorymanagement.fRate),
  ]
  const defaultMonth = Number(siteSettings.inventorymanagement.months)
  const fallbackText = siteSettings.inventorymanagement.fallbackText
  const dealerRef = siteSettings.dealers.dealerRef

  let dealerPhoneDiction = {}

  dealerRef.forEach(element => {
    dealerPhoneDiction[element.dealerId] = element.mainPhoneNumber
  });


  function formatPrice(price, defaultPrice) {
    let thePrice = (defaultPrice != undefined && price == 0
        ? defaultPrice
        : '$' + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
    return thePrice
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }
  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
        ? localStorage.setItem(item, value)
        : console.log('no localStorage')
  }
  function removeStorageItem(item){
    typeof localStorage.removeItem !== 'undefined'
        ? localStorage.removeItem(item)
        : console.log('no localStorage')
  }

  const [queryState, setQueryState] = useState(pageContentSRP? pageContentSRP:location.search )
  const SearchParam = pageContentSRP? pageContentSRP:location.search

  const params = new URLSearchParams(pageContentSRP? pageContentSRP:location.search.slice(1))
  let SearchPage = parseInt(params.get('page') || 1 )

  console.log('PARAMS')
  console.log(params)

  //initalize with an empty object
  const [StateResults, setResults] = useState({hits:[],total:0,page:SearchPage, query:{"match_all": {}},lastFilter:"",store:{
      year:[2000,2022],
      mileage:[0,200000],
      price:[0,200000]
    }});
  const [StateFavorites, setFavorites] = useState(getFavorites());
  const [StateFilters, setStateFilters] = useState(
      [{ name: "vin", value: null, label: null,query_field:"VehicleInfo.VIN",display:null },
        { name: "make", value: [], label: null,query_field:"VehicleInfo.Make",display:[] },
        { name: "dealer", value: [], label: null,query_field:"VehicleInfo.DealerName",display:[] },
        { name: "model", value: [], label: null,query_field:"VehicleInfo.Model",display:[] },
        { name: "trim", value: [], label: null,query_field:"VehicleInfo.Trim",display:[] },
        { name: "bodystyle", value: [], label: null,query_field:"VehicleInfo.BodyStyle",display:[] },
        { name: "year", value: [], label: "Year",query_field:"VehicleInfo.Year",display:[] },
        { name: "certified", value: [], label: null,query_field:"VehicleInfo.IsCertified",display:[] },
        { name: "new", value: [], label: null,query_field:"VehicleInfo.IsNew",display:[] },
        { name: "bodytype", value: [], label: null,query_field:"VehicleInfo.BodyType",display:[] },
        { name: "mileage", value: [], label: "Kilometers",query_field:"VehicleInfo.Mileage",display:[] },
        { name: "cost", value: [], label: "Price",query_field:"Pricing.CleanPrice",display:[] },
        { name: "search", value: null, label: null,query_field:[],display:null },
        { name: "fav", value: [], label: null,query_field:"VehicleInfo.VIN",display:[] },
        { name: "engine", value: [], label: null,query_field:"VehicleInfo.Engine",display:[] },
        { name: "citympg", value: null, label: null,query_field:"VehicleInfo.CityMPG",display:null },
        { name: "hwympg", value: null, label: null,query_field:"VehicleInfo.HwyMPG",display:null },
        { name: "drivetrain", value: [], label: null,query_field:"VehicleInfo.Drivetrain",display:[] },
        { name: "transmission", value: [], label: null,query_field:"VehicleInfo.CleanTransmission",display:[] },
        { name: "fuel", value: [], label: null,query_field:"VehicleInfo.CleanFuelType",display:[] },
        { name: "interiorcolor", value: [], label: null,query_field:"VehicleInfo.CleanInteriorColor",display:[] },
        { name: "exteriorcolor", value: [], label: null,query_field:"VehicleInfo.CleanExteriorColor" ,display:[]},
        { name: "sorting", value: null, label: null,query_field:[],display:null }
      ]);
  const [sliderMileageValue, setMileageValue] = useState([0, 200000]);
  const [sliderCostValue, setCostValue] = useState([0, 200000]);
  const [sliderYearValue, setYearValue] = useState([2000, 2022]);

  const [SortCards, SortCardsValue] = useState({
    label: "Sort By",
    sort_field: "VehicleInfo.CleanPhotoCount",
    sort_order: "desc",
  });

  const [filterItems, setfilterItems] = useState({
    Make: {},
    DealerName:{},
    Model: {},
    BodyType: {},
    Year: {},
    Trim:{},
    CityMPG:{},
    HwyMPG:{},
    Drivetrain:{},
    CleanFuelType:{},
    CleanInteriorColor:{},
    CleanExteriorColor:{},
    Engine:{},
    BodyStyle:{},
    CleanTransmission:{}
  })


  const getParamResults = () =>{
    let theseResults = []
    const params = new URLSearchParams(pageContentSRP ? pageContentSRP : location.search.slice(1))
    const size = Array.from(params).length

    const minmaxParam = (thefilter,Results) => {
      let queryString = ''
      let min = parseInt(params.get('min-' + thefilter))
      let max = parseInt(params.get('max-' + thefilter))
      let range = thefilter === "mileage" ? MileageRange : (thefilter === "cost" ? CostRange : 1)
      setFilter(thefilter, [min,max])
      params.delete('min-' + thefilter)
      params.delete('max-' + thefilter)

    }

    if(params.get('min-year') !== null) {
      minmaxParam("year",theseResults)
    }
    if(params.get('min-mileage') !== null) {
      minmaxParam("mileage",theseResults)
    }
    if(params.get('min-cost') !== null) {
      minmaxParam("cost",theseResults)
    }


    if (params.get('fav') === null) {
      for (const elm of params.keys()) {
        const elementsIndex = StateFilters.findIndex(con => con.name === elm)
        params.getAll(elm).forEach((p) => {
          let filterValue = p
          if (elementsIndex !== -1) {
            if(elm == 'new'  && filterValue == 'certified'){
              setFilter('certified', "true", p)
            }
            else if(elm == 'certified'){
              if(filterValue == 'true'){
                setFilter('certified', "true", p)
              }
            }
            else if(elm == 'new'){
              if(filterValue == 'true'){
                setFilter('new', "true", p)
              }
              else {
                setFilter('new', "false", p)
              }
            }
            else{
              filterValue.split(',').forEach((filterValue)=>{
                setFilter(elm, filterValue, p)
              })

            }
          }
        });
      }
    }else{
      getFavorites().forEach(fav => {

        setFilter("fav",fav)
      })
    }
  };

  function getFavorites() {
    let favs = getStorageItem("favoriteItem"+site_id)
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    return theFavs
  }

  function setFilter(filter, value,display) {
    const elementsIndex = StateFilters.findIndex(con => con.name === filter)
    let label = null;
    let arraryIndex = -1;
    if (filter === "mileage") {
      setMileageValue(value)
      StateFilters[elementsIndex].value = value
      if (value[0] === 0 && value[1] !== StateResults.store.mileage[StateResults.store.mileage.length-1]) {
        StateFilters[elementsIndex].label = 'Under ' + numberWithCommas(value[1])
      }else if (value[0] !== 0 && value[1] === StateResults.store.mileage[StateResults.store.mileage.length-1]) {
        StateFilters[elementsIndex].label = 'Over ' + numberWithCommas(value[0])
      }
      else {
        StateFilters[elementsIndex].label = numberWithCommas(value[0]) + ' - ' + numberWithCommas(value[1])
      }
    } else if (filter === "year") {
      if(typeof value === 'object') {
        StateFilters[elementsIndex].label = value[0] + ' - ' + value[1]
      }else
      {
        StateFilters[elementsIndex].label = value
        value  = [value,value]
      }

      setYearValue(value)
      StateFilters[elementsIndex].value = value

    }else if (filter === "cost") {
      setCostValue(value)
      StateFilters[elementsIndex].value = value

      if(typeof value === 'object') {

        if (value[0] === 0 && value[1] === StateResults.store.price[StateResults.store.price.length-1]) {
          StateFilters[elementsIndex].label = 'Price'
        } else if (value[0] === 0 && value[1] !== StateResults.store.price[StateResults.store.price.length-1]) {
          StateFilters[elementsIndex].label = 'Under ' + formatPrice(value[1])
        }else if (value[0] !== 0 && value[1] === StateResults.store.price[StateResults.store.price.length-1]) {
          StateFilters[elementsIndex].label = 'Over ' + formatPrice(value[0])
        }
        else {
          StateFilters[elementsIndex].label = formatPrice(value[0]) + ' - ' + formatPrice(value[1]) + (value[1] == StateResults.store.price[StateResults.store.price.length-1] ? '+' : '')
        }
      }else{
        let value0 = ((parseInt(value) - CostRange))
        let value1 =((parseInt(value)))
        StateFilters[elementsIndex].label = formatPrice(value0) + ' - ' + formatPrice(value1)
      }

    }
    else if (typeof StateFilters[elementsIndex].value === "object" && StateFilters[elementsIndex].value !== null) {

      arraryIndex = StateFilters[elementsIndex].value.indexOf(value)
      if (arraryIndex !== -1) {
        StateFilters[elementsIndex].value.splice(arraryIndex, 1);
        StateFilters[elementsIndex].display.splice(arraryIndex, 1);
      } else {
        if (value === null) {
          StateFilters[elementsIndex].value = []
          StateFilters[elementsIndex].display = []
        } else {
          StateFilters[elementsIndex].value.push(value)
          StateFilters[elementsIndex].display.push(display)
        }
      }
      if (StateFilters[elementsIndex].value.length === 0) {
        StateFilters[elementsIndex].label = null
      } else if (StateFilters[elementsIndex].value.length === 1) {
        StateFilters[elementsIndex].label = StateFilters[elementsIndex].display.length ? StateFilters[elementsIndex].display[0] :  StateFilters[elementsIndex].value[0]
      } else {
        StateFilters[elementsIndex].label = 'Multiple Types Selected'
      }
    } else {
      if (StateFilters[elementsIndex].value == value) { value = null; label = null; }

      StateFilters[elementsIndex].value = value;
      StateFilters[elementsIndex].label = label;
    }
  }

  function getFilterLabel(filter, label) {
    const elementsIndex = StateFilters.findIndex(con => con.name === filter)
    return (StateFilters[elementsIndex].label === null ? label : StateFilters[elementsIndex].label)
  }

  function getFilterValue(filter, value) {
    const elementsIndex = StateFilters.findIndex(con => con.name === filter)
    return (StateFilters[elementsIndex].value === null ? value : StateFilters[elementsIndex].value)
  }

  function createQuery(){
    let theQuery  = {
      bool:{
        must:[
         
        ]
        // should: [
         
        // ]
      }
    }
    let searchQuery = ""
    let boostQuery = ""
    let searchFilter = StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"})
    searchFilter.forEach(function (item, index) {
      searchQuery = {
        "multi_match": {
          "query":item.value,
          "type": "cross_fields",
          "operator":"and",
          "fields": [
            "VehicleInfo.CleanYear^2",
            "VehicleInfo.Make^2",
            "VehicleInfo.Model^2",
            "VehicleInfo.Trim^2",
            "VehicleInfo.CleanMake^2",
            "VehicleInfo.CleanModel^2",
            "VehicleInfo.CleanTrim^2",
            "VehicleInfo.CleanStatus^2",
            "VehicleInfo.VIN",
            "VehicleInfo.StockNumber",
            "VehicleInfo.BodyStyle",
            "VehicleInfo.BodyType",
            "VehicleInfo.CleanExteriorColor",
            "VehicleInfo.CleanInteriorColor",
            "VehicleInfo.CleanTransmission",
            "VehicleInfo.CleanFuelType",
            "VehicleInfo.DriveTrain"
          ]
        }
      }
    });
    if(typeof searchQuery == "object" ){
      theQuery.bool.must.push(searchQuery)
    }


    /* * * * * * * * * * * * * * * * * * *
    *
    *  core query Logic
    *
    * * * * * * * * * * * * * * * * * * */
    StateFilters.filter(con => {
      return typeof con.value === "object" && con.value !== null && con.value.length > 0 && con.name != "cost" && con.name != "year" && con.name != "mileage" && con.name != "new" && con.name != "certified"
    }).forEach(function (item, index) {
      let theFilter = {
        simple_query_string: {
          fields: [],
          query: ""
        }
      }
      theFilter.simple_query_string.fields.push(item.query_field)
      let queryString = "("
      if(item.name ==='fav'){
        StateFavorites.forEach(function (val, index) {
          queryString = queryString + "(" + val.toString().split(" ").join("+").split('-').join('+') + ")"
          if (index < (item.value.length - 1)) {
            queryString = queryString + "|";
          }
        })
      }else {
        item.value.forEach(function (val, index) {
          queryString = queryString + "(" + val.toString().split(" ").join("+").split('-').join('+') + ")"
          if (index < (item.value.length - 1)) {
            queryString = queryString + "|";
          }
        })
      }
      queryString = queryString + ")";
      theFilter.simple_query_string.query = queryString
      theQuery.bool.must.push(theFilter)
    });

    /* * * * * * * * * * * * * * * * * * *
    *
    *   mileage / cost / year Logic
    *
    * * * * * * * * * * * * * * * * * * */
    StateFilters.filter(con => {
      return typeof con.value === "object" && con.value !== null && con.value.length > 0 && (con.name == "cost" || con.name == "year" || con.name == "mileage")
    }).forEach(function (item, index) {
      let theFilter = {
        range: {
        }
      }
      theFilter.range[item.query_field] = {
        "gte":item.value[0],
        "lte":item.value[1]
      }
      theQuery.bool.must.push(theFilter)
    });

    /* * * * * * * * * * * * * * * * * * *
    *
    *   New/Used/Certified Logic
    *
    * * * * * * * * * * * * * * * * * * */
    let theFilter = {
      simple_query_string: {
        fields: [],
        query: ""
      }
    }
    if((IsCertified() && IsNew() && IsUsed())){
      theFilter.simple_query_string.fields.push("VehicleInfo.IsNew")
      theFilter.simple_query_string.fields.push("VehicleInfo.IsCertified")
      theFilter.simple_query_string.query = "((true || false)||(true))"
      theQuery.bool.must.push(theFilter)
    }else if(IsCertified() && IsNew()){
      theFilter.simple_query_string.fields.push("VehicleInfo.IsNew")
      theFilter.simple_query_string.fields.push("VehicleInfo.IsCertified")
      theFilter.simple_query_string.query = "((true)||(true))"
      theQuery.bool.must.push(theFilter)
    }else if(IsCertified() && IsUsed()){
      theFilter.simple_query_string.fields.push("VehicleInfo.IsNew")
      theFilter.simple_query_string.query = "((false))"
      theQuery.bool.must.push(theFilter)
    }else if(IsCertified()){
      theFilter.simple_query_string.fields.push("VehicleInfo.IsCertified")
      theFilter.simple_query_string.query = "((true))"
      theQuery.bool.must.push(theFilter)
    }else if(IsNew() && IsUsed()){
      theFilter.simple_query_string.fields.push("VehicleInfo.IsNew")
      theFilter.simple_query_string.query = "((true || false))"
      theQuery.bool.must.push(theFilter)
      theFilter = {
        simple_query_string: {
          fields: [],
          query: ""
        }
      }
      theFilter.simple_query_string.fields.push("VehicleInfo.IsCertified")
      theFilter.simple_query_string.query = "((false)"
      theQuery.bool.must.push(theFilter)
    }else if(IsNew()){
      theFilter.simple_query_string.fields.push("VehicleInfo.IsNew")
      theFilter.simple_query_string.query = "((true))"
      theQuery.bool.must.push(theFilter)
    }else if(IsUsed()){
      theFilter.simple_query_string.fields.push("VehicleInfo.IsNew")
      theFilter.simple_query_string.query = "((false))"
      theQuery.bool.must.push(theFilter)
      theFilter = {
        simple_query_string: {
          fields: [],
          query: ""
        }
      }
      theFilter.simple_query_string.fields.push("VehicleInfo.IsCertified")
      theFilter.simple_query_string.query = "((false)"
      theQuery.bool.must.push(theFilter)
    }

    return theQuery
  }

  function updateFilter(filter, value,display) {
    if (value === "null") {
      value = null
    }
    if (filter !== null) {
      captureEvent({
        event: 'asc_element_configuration',
        page_type: 'Search Results Page',
        event_action_result: 'filter',
        event_action: 'click',
        product_name: '',
        element_state: 'selected',
        element_type: '',
        element_subtype: '',
        element_title: `${filter}`,
        element_text: `${value}`,
        element_value: `${value}`,
        element_color: '#58595b',
        element_position: '',
        element_order: ''
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
      setFilter(filter, value,display);
    }
    let theQuery = createQuery()
    setSearchParams()
    getElasticData(0,srpPageCount,theQuery).then(res => { setupData(res,theQuery,1,filter) }).catch(err => { console.log(err) });
  }

  function IsCertified(){
    let IsCertified = StateFilters[StateFilters.findIndex((con) => con.name === "certified")]
    return IsCertified.value.includes(true) || IsCertified.value.includes("true")
  }
  function IsNew(){
    let IsNew = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    return IsNew.value.includes(true) || IsNew.value.includes("true")
  }
  function IsUsed(){
    let IsNew = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    return IsNew.value.includes(false) || IsNew.value.includes("false")
  }
  function getHeaderLabel(){

    let header =  ""
    let IsNewVar = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    let Make = StateFilters[StateFilters.findIndex((con) => con.name === "make")]
    let IsCertifiedVar = StateFilters[StateFilters.findIndex((con) => con.name === "certified")]

    let count = IsCertifiedVar.value.length  +  IsNewVar.value.length

    header = header + (IsNew() ? "New " : "")
    header = header + (IsNew() && (IsUsed() || IsCertified()) ? "&" :"")
    header = header + (IsCertified()  && !IsUsed() ? " Certified Pre-Owned " : "")
    header = header + (IsUsed() ? " Pre-Owned " : "")
    header = header + (count === 0 ? "New & Pre-Owned " : "")

    let MakeLabel = Make.label && Make.label.charAt(0).toUpperCase() + Make.label.slice(1)
    header = header + (Make.value.length == 1 ? MakeLabel + " " :"")

    return header
  }

  function favoriteItem(vin) {
    document.querySelector('.like-btn-' + vin).classList.toggle('like-btn_active')
    let favs = getStorageItem("favoriteItem"+site_id)
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    theFavs = theFavs.filter((value, index, self) => self.indexOf(value) === index)
    if (!theFavs.includes(vin)) {          //checking weather array contain the id
      theFavs.push(vin)
    } else {
      theFavs.splice(theFavs.indexOf(vin), 1);  //deleting
    }
    setStorageItem("favoriteItem"+site_id, JSON.stringify(theFavs));
    setFavorites(theFavs);
  }

  function setSortingItems(field, order, label) {
    SortCards.label = label;
    SortCards.sort_field = field;
    SortCards.sort_order = order
    SortCardsValue({
      label: label,
      sort_field: field,
      sort_order: order,
    });
    //updateFilter(null,null)
    let theQuery = createQuery()
    getElasticData(0,srpPageCount,theQuery).then(res => { setupData(res,theQuery,1,StateResults.filter) }).catch(err => { console.log(err) });
    console.log(field, order, label)
  }

  function getSlideValue(newValue,range){
    let slide = [];
    slide = slide.concat(Math.round(parseInt(newValue[0]) / range) * range);
    slide = slide.concat(Math.round(parseInt(newValue[1]) / range) * range);
    return slide;
  }
  function mileageChange(event, newValue) {
    setMileageValue(newValue)
    setFilter('mileage', getSlideValue(newValue,MileageRange));
  }
  function mileageCommitted(event, newValue) {
    sliderChange(event, newValue, 'mileage', setMileageValue,MileageRange)
    setMileageValue(newValue)
  }
  function costChange(event, newValue) {
    setCostValue(newValue)
    setFilter('cost', getSlideValue(newValue,CostRange));
  }
  function costCommitted(event, newValue) {
    sliderChange(event, newValue, 'cost', setCostValue,CostRange)
    setCostValue(newValue)
  }
  function yearChange(event, newValue) {
    setYearValue(newValue)
    setFilter('year', getSlideValue(newValue,1));
  }
  function yearCommitted(event, newValue) {
    sliderChange(event, newValue, 'year', setYearValue,1)
    setYearValue(newValue)
  }
  function sliderChange(event, newValue, filter, setValue,range) {
    let slide = getSlideValue(newValue,range)
    setValue(slide);
    updateFilter(filter, slide);
  }


  function setTheFilters(data,filter){
    let filters = data.data.aggregations
    let theFilterItems = Object.keys(filterItems)

    //reset the filters if they are not part of the clicked group
    theFilterItems.forEach(key => {
      if(key.toLowerCase() != filter){
        filterItems[key] = {}
      }
    });

    function isSelected(filter,key){
      const elementsIndex = StateFilters.findIndex(con => con.name === filter)
      if(elementsIndex !== -1){
        let arraryIndex = StateFilters[elementsIndex].value.indexOf(key)
        return (arraryIndex !== -1)
      }else
      {
        return false
      }
    }

    //set existing selected items
    StateFilters.filter(con => { return con.value !== null }).forEach(function (item, index) {
      if(typeof item.value ==='object'){
        item.value.forEach(function(val,index){
          if(item.name == "dealer"){
            filterItems.DealerName[val] = {show: true, count: '', selected: true}
          } else if(item.name == "make"){
            filterItems.Make[val] = {show: true, count: '', selected: true}
          } else if(item.name == "model"){
            filterItems.Model[val] = {show: true, count: '', selected: true}
          } else if(item.name == "bodytype"){
            filterItems.BodyType[val] = {show: true, count: '', selected: true}
          } else if(item.name == "trim"){
            filterItems.Trim[val] = {show: true, count: '', selected: true}
          } else if(item.name == "citympg"){
            filterItems.CityMPG[val] = {show: true, count: '', selected: true}
          } else if(item.name == "hwympg"){
            filterItems.HwyMPG[val] = {show: true, count: '', selected: true}
          } else if(item.name == "drivetrain"){
            filterItems.Drivetrain[val] = {show: true, count: '', selected: true}
          } else if(item.name == "fuel"){
            filterItems.CleanFuelType[val] = {show: true, count: '', selected: true}
          } else if(item.name == "interiorcolor"){
            filterItems.CleanInteriorColor[val] = {show: true, count: '', selected: true}
          }else if(item.name == "exteriorcolor"){
            filterItems.CleanExteriorColor[val] = {show: true, count: '', selected: true}
          }else if(item.name == "engine"){
            filterItems.Engine[val] = {show: true, count: '', selected: true}
          }else if(item.name == "bodystyle"){
            filterItems.BodyStyle[val] = {show: true, count: '', selected: true}
          }else if(item.name == "transmission"){
            filterItems.CleanTransmission[val] = {show: true, count: '', selected: true}
          }
        });
      }
    });

    //set data returned from the elastic search
    filters["searched_location"].buckets.forEach(filter => {
      filterItems.DealerName[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('dealer',filter.key)}
    })
    filters["searched_make"].buckets.forEach(filter => {
      filterItems.Make[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('make',filter.key)}
    })
    filters["searched_model"].buckets.forEach(filter => {
      filterItems.Model[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('model',filter.key)}
    })
    filters["searched_body_type"].buckets.forEach(filter => {
      filterItems.BodyType[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('bodytype',filter.key)}
    })
    filters["searched_trim"].buckets.forEach(filter => {
      filterItems.Trim[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('trim',filter.key)}
    })
    filters["searched_body_style"].buckets.forEach(filter => {
      filterItems.BodyStyle[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('bodystyle',filter.key)}
    })
    filters["searched_drive_type"].buckets.forEach(filter => {
      filterItems.Drivetrain[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('drivetype',filter.key)}
    })
    filters["searched_fuel_type"].buckets.forEach(filter => {
      filterItems.CleanFuelType[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('fuel',filter.key)}
    })
    filters["searched_transmission"].buckets.forEach(filter => {
      filterItems.CleanTransmission[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('transmission',filter.key)}
    })
    filters["searched_int_color"].buckets.forEach(filter => {
      filterItems.CleanInteriorColor[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('CleanInteriorColor',filter.key)}
    })
    filters["searched_color"].buckets.forEach(filter => {
      filterItems.CleanExteriorColor[filter.key === '' ? 'Other' : filter.key] = {show: true, count: '(' + filter.doc_count + ')', selected: isSelected('exteriorcolor',filter.key)}
    })

  }

  function scrollTop(){
    window.scrollTo(0,0)
  }
  function loadingData(loading){
    if(loading){
      document.querySelectorAll('.srp-loading-hide').forEach(a=>a.style.display = "none");
      document.querySelectorAll('.srp-loading-show').forEach(a=>a.style.display = "block");
    }else {
      document.querySelectorAll('.srp-loading-hide').forEach(a=>a.style.display = "flex");
      document.querySelectorAll('.srp-loading-show').forEach(a=>a.style.display = "none");
    }
    if(typeof window !== undefined){
      setTimeout(window.dispatchEvent(DealerMasterEvent),1000);
      console.log("loadingData",DealerMasterEvent)
    }
  }


  function getStoreMinMax(aggregations,first){
    let mileageArr = []
    let yearArr = []
    let priceArr = []
    aggregations["searched_mileage"].buckets.forEach(mile =>{
      mileageArr.push(getMileage(mile.key))
    })
    aggregations["searched_year"].buckets.forEach(year =>{
      yearArr.push(year.key)
    })
    aggregations["searched_price"].buckets.forEach(price =>{
      priceArr.push(getCost(price.key))
    })

    let store = {
      year:[Math.min(...yearArr),Math.max(...yearArr)],
      mileage:[0,Math.max(...mileageArr)],
      price:[0,Math.max(...priceArr)]
    }

    store.mileage[0] = (store.mileage[0] < StateResults.store.mileage[0]) ? store.mileage[0] : StateResults.store.mileage[0]
    store.mileage[1] = (store.mileage[1] > StateResults.store.mileage[1]) ? store.mileage[1] : StateResults.store.mileage[1]
    store.price[0] =  (store.price[0] < StateResults.store.price[0]) ? store.price[0] : StateResults.store.price[0]
    store.price[1] = (store.price[1] >  StateResults.store.price[1]) ? store.price[1] : StateResults.store.price[1]
    store.year[0] = (store.year[0] < StateResults.store.year[0]) ? store.year[0] : StateResults.store.year[0]
    store.year[1] = (store.year[1] >  StateResults.store.year[1]) ? store.year[1] : StateResults.store.year[1]

    if((sliderMileageValue[0]< StateResults.store.mileage[0]) || first){
      sliderMileageValue[0] = store.mileage[0]
    }
    if((sliderMileageValue[1] > StateResults.store.mileage[1]) || first){
      sliderMileageValue[1] = store.mileage[1]
    }
    if((sliderCostValue[0] < StateResults.store.price[0]) || first){
      sliderCostValue[0] = store.price[0]
    }
    if((sliderCostValue[1] > StateResults.store.price[1]) || first){
      sliderCostValue[1] = store.price[1]
    }
    if((sliderYearValue[0] < StateResults.store.year[0]) || first){
      sliderYearValue[0] = store.year[0]
    }
    if((sliderYearValue[1] > StateResults.store.year[1]) || first){
      sliderYearValue[1] = store.year[1]
    }

    return store
  }
  function setupData(res,theQuery,thePage,filter,first){
    let theResults = []
    res.data.hits.hits.forEach(hit => {
      hit._source.MainPhotoUrl = hit._source.VehicleInfo.MainPhotoUrl != "" ? hit._source.VehicleInfo.MainPhotoUrl : unavailableImg
      theResults.push(hit._source)
    })
    const hitCount = res.data.hits.total.value;

    if(hitCount === 0 ){
      loadingData(true)
      getElasticData(0,0,{"match_all": {}}).then(res => {
        let store = getStoreMinMax(res.data.aggregations,first)
        setTheFilters(res,filter)
        loadingData(false)
        setResults({hits:theResults,total:hitCount,page:thePage, query:theQuery, lastFilter: filter,store:store})
        return;
      })
    }else{
      let store = getStoreMinMax(res.data.aggregations,first)
      setTheFilters(res,filter)
      loadingData(false)
      setResults({hits:theResults,total:res.data.hits.total.value,page:thePage, query:theQuery, lastFilter: filter,store:store})
    }

  }
  function getElasticData(from,size,query){
    let body = {
      "SiteID": site_id,
      "search_query":{
        "from": from,
        "size": size,
        "query": query,
        "sort": [
             {
               "_script": {
                 "type": "number",
                 "script": {
                   "lang": "painless",
                   "source": "doc['Pricing.ExtraPrice1'].value == 0 && doc['Pricing.List'].value == 0 ?  1 : 0"
                 },
                 "order": "asc"
               }
             }],
        
        "_source": [
          "VehicleInfo.MainPhotoUrl",
          "VehicleInfo.VIN",
          "VehicleInfo.DealerName",
          "VehicleInfo.Trim",
          "VehicleInfo.Make",
          "VehicleInfo.Model",
          "VehicleInfo.Year",
          "VehicleInfo.Mileage",
          "VehicleInfo.BodyStyle",
          "VehicleInfo.BodyType",
          "VehicleInfo.Drivetrain",
          "VehicleInfo.Transmission",
          "VehicleInfo.Engine",
          "VehicleInfo.StockNumber",
          "VehicleInfo.IsCertified",
          "VehicleInfo.IsNew",
          "Pricing.List",
          "Pricing.ExtraPrice1",
          "Pricing.CleanPrice",
          "VehicleInfo.DealerID"
        ],
        
        
        "aggs": {
          "searched_is_new": {
            "terms": {
              "field": "VehicleInfo.IsNew"
            }
          },
          "searched_mileage": {
            "terms": {
              "field": "VehicleInfo.Mileage",
              "size":20000
            }
          },
          "searched_price": {
            "terms": {
              "field": "Pricing.CleanPrice",
              "size":20000
            }
          },
          "searched_year": {
            "terms": {
              "field": "VehicleInfo.Year",
              "size":20000
            }
          },
          "searched_is_cert": {
            "terms": {
              "field": "VehicleInfo.IsCertified"
            }
          },
          "searched_location": {
            "terms": {
              "field": "VehicleInfo.DealerName.keyword",
              "size":20000
            }
          },
          "searched_make": {
            "terms": {
              "field": "VehicleInfo.Make.keyword",
              "size":20000
            }
          },
          "searched_model": {
            "terms": {
              "field": "VehicleInfo.Model.keyword",
              "size":20000
            }
          },
          "searched_trim": {
            "terms": {
              "field": "VehicleInfo.Trim.keyword",
              "size":20000
            }
          },
          "searched_body_style": {
            "terms": {
              "field": "VehicleInfo.BodyStyle.keyword",
              "size":20000
            }
          },
          "searched_body_type": {
            "terms": {
              "field": "VehicleInfo.BodyType.keyword",
              "size":20000
            }
          },
          "searched_color": {
            "terms": {
              "field": "VehicleInfo.CleanExteriorColor.keyword",
              "size":20000
            }
          },
          "searched_int_color": {
            "terms": {
              "field": "VehicleInfo.CleanInteriorColor.keyword",
              "size":20000
            }
          },
          "searched_drive_type": {
            "terms": {
              "field": "VehicleInfo.Drivetrain.keyword",
              "size":20000
            }
          },
          "searched_transmission": {
            "terms": {
              "field": "VehicleInfo.CleanTransmission.keyword",
              "size":20000
            }
          },
          "searched_fuel_type": {
            "terms": {
              "field": "VehicleInfo.CleanFuelType.keyword",
              "size":20000
            }
          }
        }
      }
    }

    // Add custom sorting script to the query sent to Elasticsearch
    if (SortCards.sort_field === 'Pricing.CleanPrice') {

      // First sort to move items with Pricing.ExtraPrice1 == 0 to the end
      body.search_query.sort.push({
        "_script": {
          "type": "number",
          "script": {
            "lang": "painless",
            "source": "doc['Pricing.ExtraPrice1'].value == 0 ? 1 : 0"
          },
          "order": "asc" // Moves items with ExtraPrice1 == 0 to the end
        }
      });

      // Then sort by Pricing.CleanPrice as specified
      body.search_query.sort.push({
        "Pricing.CleanPrice": {
          "order": SortCards.sort_order
        }
      });
      
    } else {
      // Add the sort for other fields
      body.search_query.sort.push({
        [SortCards.sort_field]: {
          "order": SortCards.sort_order
        }
      });
    }

    let searchFilter = StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"})
    if(searchFilter.length == 0 ) {
      body.search_query.sort.push(
        {
         [SortCards.sort_field]: {
        "order": SortCards.sort_order
      }
    }
    )
    }
    loadingData(true)
    let url = open_search_url + getSearchParams()
    return axios.post(url,{
      body
    })
  }

  function msrpClass(amount){
    let theClass = "sr__costs"
    if(amount == 0){
      theClass = theClass + " msrp__hide"
    }
    return theClass
  }
  function insertUrlParam(key, value) {
    if (history.pushState) {
      let searchParams = new URLSearchParams(pageContentSRP? pageContentSRP:window.location.search)
      searchParams.set(key, value);
      searchParams.set("page",StateResults.page)
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      history.replaceState({path: newurl}, '', newurl);
    }
  }
  function getSearchParams(){
    let newurl = '?'
    if (history.pushState) {
      let searchParams = new URLSearchParams(pageContentSRP? pageContentSRP:window.location.search)
      newurl = '?' + searchParams.toString();
    }
    return newurl
  }

  function removeUrlParameter(paramKey) {
    const url = window.location.href
    var r = new URL(url)
    const beforeURL = r.href
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    if(beforeURL !=newUrl ){
      history.replaceState({ path: newUrl }, '', newUrl)
    }
  }
  function setSearchParams() {
    if ('URLSearchParams' in window) {
      let searchParams = new URLSearchParams(window.location.search)

      StateFilters.filter(con => { return con.value === null }).forEach(function (item, index) {
        removeUrlParameter(item.name)
      });
      StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length == 0 }).forEach(function (item, index) {
        removeUrlParameter(item.name)
      });

      StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 }).forEach(function (item, index) {
        if(!["price","cost","mileage","year"].includes(item.name)){
          insertUrlParam(item.name, item.value);
        }else{
          insertUrlParam("min-" + item.name, item.value[0]);
          insertUrlParam("max-" + item.name, item.value[1]);
        }
      });
      StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"}).forEach(function (item, index) {
        insertUrlParam(item.name, item.value.trim());
      });

    }

  }

  function resetSearchPage(){

    let theParams = []
    for (const elm of params.keys()) {
      theParams = theParams.concat(elm)
    }

    //resets any filter that isn't an object, back to
    StateFilters.filter(con => { return typeof con.value !== "object" && con.value !== null }).map((f) => {
      if(!theParams.includes(f.name)){
        if(!["price","cost","mileage","year"].includes(f.name)){
          setFilter(f.name,f.value)
        }
      }
    })
    StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 }).map((f) => {

      if(!theParams.includes(f.name)){
        if(!["price","cost","mileage","year"].includes(f.name)){
          if(f.value.length > 0 && f.name != 'certified'){
            f.value.forEach((v) => { setFilter(f.name,v) })
          }
        }
      }
      else{
        let theParamValue = params.getAll(f.name)
        let theParamValueArr = []
        theParamValue.forEach((param)=>{param.split(",").forEach((p)=>{theParamValueArr.push(p)})})
        f.value.forEach((v) => { if(!theParamValueArr.includes(v)){setFilter(f.name,v)}});
      }
    })
    if(document.querySelector('.open-filters-srp-page-wrap_active') !== null){
      document.querySelector('.open-filters-srp-page-wrap_active').classList.toggle('open-filters-srp-page-wrap_active');
    }
    if(document.querySelector('.open-filters-btn__text_active') !== null){
      document.querySelector('.open-filters-btn__text_active').classList.toggle('open-filters-btn__text_active');
    }
    if(document.querySelector('.filters-wrap_visible') !== null){
      document.querySelector('.filters-wrap_visible').classList.toggle('filters-wrap_visible')
    }
    let theQuery = SearchParam.length === 0 ? StateResults.query :  createQuery()
    let from = (SearchPage == 1) ? 0 : ((SearchPage-1)* srpPageCount)
    getElasticData(from,srpPageCount,theQuery).then(res => { setupData(res,theQuery,SearchPage,StateResults.lastFilter,true) }).catch(err => { console.log(err) })

  }


    useEffect(()=>{
      if(pageContentSRP !== (null || undefined)){
        const url = window.location.href
        history.replaceState({ path: url }, null, `?${pageContentSRP}`);
  
      }
    getParamResults()
    resetSearchPage()
    if(typeof window !== 'undefined')
      window.dispatchEvent(DealerMasterEvent)
  },[SearchParam])

  //pageination
  function getNextPage(){
    let theNextPage = (StateResults.page + 1)

    if(theNextPage <=  Math.ceil(StateResults.total / srpPageCount)){
      insertUrlParam("page",theNextPage)
      scrollTop()
      getElasticData((StateResults.page * srpPageCount) ,srpPageCount,createQuery()).then(res => { setupData(res,StateResults.query,theNextPage,StateResults.lastFilter) }).catch(err => { console.log(err) })
    }
  }
  function getPrevPage(){
    let thePrevPage = (StateResults.page - 1)

    if(thePrevPage >  0){
      insertUrlParam("page",thePrevPage)
      scrollTop()
      getElasticData(((thePrevPage-1) * srpPageCount) ,srpPageCount,createQuery()).then(res => { setupData(res,StateResults.query,thePrevPage,StateResults.lastFilter) }).catch(err => { console.log(err) })
    }
  }


  const [isOpen, toggleOpen] = useState([false, false, false,false,false,false,false])
  const toggleMenu = (idx) => {
    const _isOpen = isOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    if(idx === 3){
      document.querySelector('.more-filters').classList.toggle('more-filters_visible')
    }
    toggleOpen(_isOpen)
  }

  const [isMoreOpen, toggleMoreOpen] = useState([false, false, false,false,false])
  const toggleMoreMenu = (idx) => {
    const _isMoreOpen = isMoreOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    toggleMoreOpen(_isMoreOpen)
  }

  function toggleClass(mainClassName,toggleClassName,checkedClassName){
    document.querySelector('.' + toggleClassName).classList.toggle(checkedClassName)
  }
  const hideMenus = () => {
    const _isOpen = isOpen.map(() => (false))
    toggleOpen(_isOpen)
  }
  function getFilterItems(Item){
    return Object.keys(filterItems[Item]).sort().filter((r) => filterItems[Item][r].show)
  }
  function loadFirstPhoto () {
    let mainPhoto = ""
    if (mainPhoto === "")
    {
      mainPhoto = unavailableImg
    }
    return mainPhoto
  }

  if(typeof window !== 'undefined')
    window.dispatchEvent(DealerMasterEvent)

  return (
      <Layout seo={pageSeo} footerClassName={"srp-footer"}>
        <link rel="preload" as="image" href={loadFirstPhoto()} />
        <div className="srp-page-wrap"  style={{ padding: "20px"}}>
          {/* CONDITIONAL HERE */}
          {pageContext?.pageContent?.SRP?.disableTopHtml?
            null
            :
            <InnerHTML html={topHtmlSRP} />
          }
          <InnerHTML html={staticTopHtmlSrp} />
          <button className="open-filters-btn"
                  onClick={() => { document.querySelector('.srp-page-wrap').classList.toggle('open-filters-srp-page-wrap_active');  document.querySelector('.open-filters-btn').classList.toggle('open-filters-btn__text_active');  document.querySelector('.content-wrap').classList.toggle('filters-wrap_visible')}}>
            <span className="open-filters-btn__text"><span className="open">Open</span> <span className="close">Close</span>  Filters</span>
          </button>
          <div className="content-wrap">
            <div className="sidebar">
              <div className="sidebar__section-01">
                <div className="switch-btns">
                  <button className={ IsNew() ? 'switch-btns__btn condition-new-switch-btns__btn switch-btns__btn_active' : 'switch-btns__btn condition-new-switch-btns__btn' } onClick={() => { updateFilter('new','true'); toggleClass("switch-btns__btn","condition-new-switch-btns__btn","switch-btns__btn_active")}}>New</button>
                  <button className={ (IsUsed() ? "switch-btns__btn condition-used-switch-btns__btn switch-btns__btn_active" : "switch-btns__btn condition-used-switch-btns__btn ") } onClick={() => { updateFilter('new','false'); toggleClass("switch-btns__btn","condition-used-switch-btns__btn","switch-btns__btn_active"); }}>Used</button>
                  <button className={ (IsCertified() ? "switch-btns__btn condition-certified-switch-btns__btn switch-btns__btn_active" : "switch-btns__btn condition-certified-switch-btns__btn ") } onClick={() => { updateFilter('certified',true); toggleClass("switch-btns__btn","condition-certified-switch-btns__btn","switch-btns__btn_active");}}>Certified</button>
                </div>
                <div className={"options-list-v2 dealer "+ (getFilterItems("DealerName").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.dealer.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Location: <span className="options-list-v2__selected-value">{getFilterLabel('dealer','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("DealerName").map(Dealer => {
                      let toggleClassName = "dealer-" + Dealer.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "options-list-v2__item"
                      return (
                          <label className={mainClassName + " " + toggleClassName + (filterItems.DealerName[Dealer].selected ? " " + checkedClassName : "")} >
                            <input type="checkbox"  className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('dealer', Dealer,Dealer); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{Dealer} {filterItems.DealerName[Dealer].count} </span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 make "+ (getFilterItems("Make").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.make.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Make: <span className="options-list-v2__selected-value">{getFilterLabel('make','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("Make").map(Make => {
                      let toggleClassName = "make-" + Make.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "options-list-v2__item"
                      return (
                          <label className={mainClassName + " " + toggleClassName + (filterItems.Make[Make].selected ? " " + checkedClassName : "")} >
                            <input type="checkbox"  className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('make', Make,Make); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{Make} {filterItems.Make[Make].count} </span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 model "+ (getFilterItems("Model").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.model.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Model: <span className="options-list-v2__selected-value">{getFilterLabel('model','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("Model").map(Model => {
                      let toggleClassName = "model-" + Model.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "options-list-v2__item"
                      return (
                          <label className={mainClassName + " " + toggleClassName + (filterItems.Model[Model].selected ? " " + checkedClassName : "")} >
                            <input type="checkbox"  className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('model', Model,Model); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{Model} {filterItems.Model[Model].count} </span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 trim "+ (getFilterItems("Trim").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.trim.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Trim: <span className="options-list-v2__selected-value">{getFilterLabel('trim','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("Trim").map(Trim => {
                      let toggleClassName = "trim-" + Trim.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "options-list-v2__item"
                      return (
                          <label className={mainClassName + " " + toggleClassName + (filterItems.Trim[Trim].selected ? " " + checkedClassName : "")} >
                            <input type="checkbox"  className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('trim', Trim,Trim); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{Trim} {filterItems.Trim[Trim].count} </span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 fuel "+ (getFilterItems("CleanFuelType").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.fuel.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Fuel/Electric/Hybrid: <span className="options-list-v2__selected-value">{getFilterLabel('fuel','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("CleanFuelType").map(CleanFuelType => {
                      let toggleClassName = "fuel-" + CleanFuelType.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "fuel.options-list-v2__item"
                      return (
                          <label className={"fuel options-list-v2__item " + toggleClassName + (filterItems.CleanFuelType[CleanFuelType].selected ? " " + checkedClassName : "")}>
                            <input type="checkbox" className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('fuel', CleanFuelType,CleanFuelType); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{CleanFuelType} {filterItems.CleanFuelType[CleanFuelType].count}</span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className="options-list-v2 year enabled">
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.year.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Year: <span className="options-list-v2__selected-value" >{getFilterLabel('year', 'Year') == "Year" ?  StateResults.store.year[0] + " - " + StateResults.store.year[StateResults.store.year.length - 1] : getFilterLabel('year', 'Year') }</span></span>
                    <span className="options-list-v2__header-arrow" ></span>
                  </button>
                  <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                    <Slider
                        value={sliderYearValue}
                        onChange={yearChange}
                        onChangeCommitted={yearCommitted}
                        // valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        steps={1}
                        min={StateResults.store.year[0]}
                        max={StateResults.store.year[StateResults.store.year.length - 1]}
                    />
                  </div>
                </div>
                <div className="options-list-v2 price enabled">
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.price.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Price: <span className="options-list-v2__selected-value" >{getFilterLabel('cost', '0') === "Price" ? "$" + "0.00 - $" + numberWithCommas(StateResults.store.price[StateResults.store.price.length - 1]) + ".00+"  : getFilterLabel('cost', '0')}</span></span>
                    <span className="options-list-v2__header-arrow" ></span>
                  </button>
                  <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                    <Slider
                        value={sliderCostValue}
                        onChange={costChange}
                        onChangeCommitted={costCommitted}
                        // valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        steps={CostRange}
                        min={0}
                        max={StateResults.store.price[StateResults.store.price.length - 1]}
                    />
                  </div>
                </div>
                <div className="options-list-v2 mileage enabled">
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.mileage.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Kilometers: <span className="options-list-v2__selected-value" >{getFilterLabel('mileage', 'Kilometers') == "Kilometers" ?  numberWithCommas(StateResults.store.mileage[0]) + " - " + numberWithCommas(StateResults.store.mileage[StateResults.store.mileage.length - 1]) +"+": getFilterLabel('mileage', 'Kilometers') }</span></span>
                    <span className="options-list-v2__header-arrow" ></span>
                  </button>
                  <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                    <Slider
                        value={sliderMileageValue}
                        onChange={mileageChange}
                        onChangeCommitted={mileageCommitted}
                        // valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        steps={MileageRange}
                        min={0}
                        max={StateResults.store.mileage[StateResults.store.mileage.length - 1]}
                    />
                  </div>
                </div>
                <div className={"options-list-v2 bodystyle "+ (getFilterItems("BodyStyle").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.bodystyle.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Body Style: <span className="options-list-v2__selected-value">{getFilterLabel('bodystyle','')}</span></span>
                    <span className="options-list-v2__header-arrow" ></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("BodyStyle").map(BodyStyle => {
                      let toggleClassName = "bodystyle_" + BodyStyle.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "bodystyle.options-list-v2__item"
                      return (
                          <label className={"bodystyle options-list-v2__item options-list-v2__item_bodystyle " + toggleClassName + (filterItems.BodyStyle[BodyStyle].selected ? " " + checkedClassName : "")}>
                            <input type="checkbox" className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('bodystyle', BodyStyle,BodyStyle); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{BodyStyle} {filterItems.BodyStyle[BodyStyle].count}</span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 exterior "+ (getFilterItems("CleanExteriorColor").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.exterior.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Exterior Color: <span className="options-list-v2__selected-value">{getFilterLabel('exteriorcolor','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>

                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("CleanExteriorColor").map(CleanExteriorColor => {
                      let toggleClassName = "color_" + CleanExteriorColor.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "exterior.options-list-v2__item"
                      return (
                          <label className={"exterior options-list-v2__item options-list-v2__item_color " + toggleClassName + (filterItems.CleanExteriorColor[CleanExteriorColor].selected ? " " + checkedClassName : "")}>
                            <input type="checkbox" className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('exteriorcolor', CleanExteriorColor,CleanExteriorColor); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{CleanExteriorColor} {filterItems.CleanExteriorColor[CleanExteriorColor].count} </span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 interior "+ (getFilterItems("CleanInteriorColor").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.interior.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Interior Color: <span className="options-list-v2__selected-value">{getFilterLabel('interiorcolor','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("CleanInteriorColor").map(CleanInteriorColor => {
                      let toggleClassName = "color_" + CleanInteriorColor.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "interior.options-list-v2__item"
                      return (
                          <label className={"interior options-list-v2__item options-list-v2__item_color " + toggleClassName + (filterItems.CleanInteriorColor[CleanInteriorColor].selected ? " " + checkedClassName : "")}>
                            <input type="checkbox" className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('interiorcolor', CleanInteriorColor,CleanInteriorColor); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{CleanInteriorColor} {filterItems.CleanInteriorColor[CleanInteriorColor].count}</span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 drivetrain "+ (getFilterItems("Drivetrain").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.drivetrain.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Drive Type: <span className="options-list-v2__selected-value">{getFilterLabel('drivetrain','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("Drivetrain").map(Drivetrain => {
                      let toggleClassName = "drivetrain-" + Drivetrain.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "drivetrain.options-list-v2__item"
                      return (
                          <label className={"drivetrain options-list-v2__item " + toggleClassName + (filterItems.Drivetrain[Drivetrain].selected ? " " + checkedClassName : "")}>
                            <input type="checkbox" className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('drivetrain', Drivetrain,Drivetrain); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{Drivetrain} {filterItems.Drivetrain[Drivetrain].count}</span>
                          </label>
                      )
                    })}
                  </div>
                </div>
                <div className={"options-list-v2 transmission "+ (getFilterItems("CleanTransmission").length === 0 ?"disable": "enabled") }>
                  <button className="options-list-v2__header options-list-v2__header_color"
                          onClick={() => {document.querySelector('.transmission.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                    <span>Transmission: <span className="options-list-v2__selected-value">{getFilterLabel('transmission','')}</span></span>
                    <span className="options-list-v2__header-arrow"></span>
                  </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                    {getFilterItems("CleanTransmission").map(CleanTransmission => {
                      let toggleClassName = "transmission-" + CleanTransmission.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                      let checkedClassName = "options-list-v2__item_checked"
                      let mainClassName = "transmission.options-list-v2__item"
                      return (
                          <label className={"transmission options-list-v2__item " + toggleClassName + (filterItems.CleanTransmission[CleanTransmission].selected ? " " + checkedClassName : "")}>
                            <input type="checkbox" className="options-list-v2__checkbox"
                                   onClick={() => {updateFilter('transmission', CleanTransmission,CleanTransmission); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list-v2__text">{CleanTransmission} {filterItems.CleanTransmission[CleanTransmission].count}</span>
                          </label>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="sidebar-mobile-btns-reset">
                <Link to={(typeof window !== 'undefined' ? window.location.href : "/")} className="switch-btns__btn srp-search__clear-results" >Clear</Link>
                <button className="switch-btns__btn srp-search__see-results"
                        onClick={() => {window.scrollTo(0,0);document.querySelector('.srp-page-wrap').classList.toggle('open-filters-srp-page-wrap_active');  document.querySelector('.open-filters-btn').classList.toggle('open-filters-btn__text_active');  document.querySelector('.content-wrap').classList.toggle('filters-wrap_visible')}}>
                  See {StateResults.total} Results </button>
              </div>
            </div>

            <div className="sr-wrap">
              <div className="srp-row srp-search">
                <form className="srp-search__search-form">
                  <input className="srp-search__search-input"  style={{fontSize: "16px"}} type="search" onKeyPress={function(e) {
                    var KeyID = e.which;
                    switch(KeyID)
                    {
                      case 32:
                        updateFilter("search", e.target.value)
                        break;
                      case 13:
                        e.preventDefault();
                        updateFilter("search", e.target.value)
                        break;
                      default:
                        if(e.target.value.trim() == ''){
                          updateFilter("search", e.target.value.trim())
                          removeUrlParameter("search")
                        }
                        break;
                    }
                  }} placeholder={'Type Here'}
                         defaultValue={
                           (StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"}).length > 0) ?
                               StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"})[0].value :
                               ''}  />
                  <button className="srp-search__reset-btn">Reset Filters</button>
                </form>
              </div>
              <div className="srp-row srp-row-top srp-loading-hide">
                <h1 className="inventory-type-title">
                  {getHeaderLabel()} Inventory
                </h1>
              </div>
              <div className="srp-row srp-result-count srp-loading-hide">
                <p className="num-of-vehicles">
                  <span id="results" className="num-of-vehicles__number">{StateResults.total}</span> vehicles found
                </p>

                <a href="?fav=true" aria-label="Favorites" className="favorites-link">Favorites (<span className="favorites-link__num">{StateFavorites.length}</span>)</a>
                <ul className="dropdown-sorting"  style={{  "min-width": "300px"}} onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)}>
                  <li className="dropdown-v1 srp-dropdown__model" onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)}>
                    <div className="sr-dropdown__inner">
                      <p className="sr-dropdown__name">{SortCards.label ?? "Sort By"}</p>
                    </div>
                    {isOpen[6] &&
                    (<ul className="sub-dropdown-v1 search-dropdown">
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Pricing.CleanPrice", "asc", "Price - (Lowest to Highest)"); }}>Price - (Lowest to Highest)</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Pricing.CleanPrice", "desc", "Price - (Highest to Lowest)"); }}>Price - (Highest to Lowest)</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("VehicleInfo.Year", "desc", "Year -  (Newer to Older)"); }}>Year -  (Newer to Older)</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("VehicleInfo.Year", "asc", "Year -  (Older to Newer)"); }}>Year -  (Older to Newer)</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("VehicleInfo.Mileage", "asc", "Kilometers - (Lowest to Highest)"); }}>Kilometers - (Lowest to Highest)</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("VehicleInfo.Mileage", "desc", "Kilometers - (Highest to Lowest)"); }}>Kilometers - (Highest to Lowest)</li>
                    </ul>)}
                  </li>
                </ul>
              </div>
              <div className={StateResults.total === 0 ? "num-of-vehicles sr__no_results srp-loading-hide" :"sr__has_results" }> Our inventory is changing constantly. Check back soon.</div>
              <FinanceCalculatorProvider>
                <SearchRecommended props={{
                  fallbackText, siteSettings,StateResults, setFavorites, theSlug, useCompactCards,
                  SRPCardBtns, msrpClass, formatPrice, unavailableImg,
                  lead_url, site_id,gtmId, disclaimerCalculator, disclaimerContact,
                  disclaimerFinanceApp, defaultMonth, downpayment, rates,DealerMasterEvent, dealerPhoneDiction, overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers
                }}/>
              </FinanceCalculatorProvider>
              {Math.ceil(StateResults.total / srpPageCount) > 0 &&
              <div className={StateResults.total === 0 ? "pagination-srp srp-loading-hide" : "pagination-srp srp-loading-hide"}>
                <a onClick={() => getPrevPage()} className="srp-previous srp-round">&#8249;</a>
                <span
                    className="srp-pagination-text"> PAGE {StateResults.page} OF {Math.ceil(StateResults.total / srpPageCount)} </span>
                <a onClick={() => getNextPage()} className="srp-next srp-round">&#8250;</a>
              </div>
              }
            </div>
          </div>
          {pageContext?.pageContent?.SRP?.disableBottomHtml?
            null
            :
            <div dangerouslySetInnerHTML={{ __html: botHtmlSRP }} />
          }
          <div dangerouslySetInnerHTML={{ __html: staticBottomHtmlSRP }} />
          <div className="sr-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />
          <data id="page" value="Search Results Page"></data>
        </div>
      </Layout >
  )
}
export default SearchPage

export const pageQuery = graphql`
  query {
  site {
    siteMetadata {
      site_id
      lead_url
      opensearchAPI
    }
  }
  siteData{
   siteData {
      site_id
      siteSettings
      siteMetadata {
        currentTemplateID
        siteName
      }
    }
  }
  }`